import { api } from '@/utils/axiosInterceptor';
import { ACTION, KEYPAIR } from '@/types/interfaces';
import { handleErrors } from '@/utils/handleError';
const HOME_API_URL = process.env.BACKEND_API_URL + 'home/';

/* Get Error Logs */
//eslint-disable-next-line no-unused-vars
export async function GET_SEARCH(action: ACTION): Promise<unknown> {
  const { payload } = action;
  const res: ReturnType<any> = await api(`${HOME_API_URL}search`, 'POST', {
    data: payload,
  });
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

export async function GET_DETAILS(action: ACTION): Promise<unknown> {
  const { payload } = action;
  const res: ReturnType<any> = await api(`${HOME_API_URL}detail/${payload?.slug}`);
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

export async function GET_HOME(): Promise<unknown> {
  const res: ReturnType<any> = await api(`${HOME_API_URL}home`);
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

// Server Side Rendering
/**
 *
 * @param action Request payload
 * @returns Users List
 */
export async function getDescription(action: ACTION): Promise<unknown> {
  const { payload } = action as { payload: KEYPAIR };
  const res: ReturnType<any> = await fetch(`${process.env.BACKEND_URL}/v1/front/api/home/description`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (!res.ok) {
    const errorText = await res.text();
    throw new Error(`HTTP error! status: ${res.status}, response: ${errorText}`);
  }
  return res?.json();
}

// Server Side Rendering
/**
 *
 * @param action Request payload
 * @returns Users List
 */
export async function getHome(): Promise<unknown> {
  const res: ReturnType<any> = await fetch(`${process.env.BACKEND_URL}/v1/front/api/home/home`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!res.ok) {
    const errorText = await res.text();
    throw new Error(`HTTP error! status: ${res.status}, response: ${errorText}`);
  }
  return res?.json();
}
